<template>
  <div>
    <div class="message-box">
      <div class="title">商品共享信息</div>
      <div class="message-box-box">
        <div>
          共享分佣比例
          <div>{{ basicinfoData.sharedBrokerageRatio }}%</div>
        </div>
        <div>
          分销供应商
          <div>
            <span v-if="basicinfoData.sharedSaleType == 0">全部可售</span>
            <span v-if="basicinfoData.sharedSaleType == 1">指定供应商可售</span>
            <span v-if="basicinfoData.sharedSaleType == 2"
              >指定供应商不可售</span
            >
            <div
              class="click-box"
              v-if="
                basicinfoData.sharedSaleType == 1 ||
                basicinfoData.sharedSaleType == 2
              "
            >
              <el-dropdown>
                <span class="el-dropdown-link">
                  {{ basicinfoData.businessname[0] }}<i class="el-icon-arrow-down el-icon--right"></i>
                </span>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item v-for="(item,index) in basicinfoData.businessname" :key="index">{{item}}</el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </div>
          </div>
        </div>
        <div>
          分销商圈
          <div>
            <span>{{ basicinfoData.businessDistrictname || "无" }}</span>
            <span
              class="click-box"
              v-if="basicinfoData.businessDistrictname"
              @click="viewDq(basicinfoData)"
            >
              点击查看具体区域
            </span>
          </div>
        </div>
        <!-- <el-form label-width="120px">
          <el-form-item label="共享分佣比例" class="color666">
              <span>{{basicinfoData.sharedBrokerageRatio}}%</span>          
          </el-form-item>
          <el-form-item label="供应商">
            <el-radio-group v-model="basicinfoData.sharedSaleType" disabled>
              <el-radio label="0">全部可售</el-radio>
              <el-radio label="1">指定供应商可售</el-radio>
              <el-radio label="2">指定供应商不可售</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="指定供应商：" v-if="basicinfoData.sharedSaleType == 1">
            <div>
              <span class="box-1-span" v-for="(item, index) in basicinfoData.businessname" :key="index">
                {{ item }}</span>
            </div>
          </el-form-item>
          <el-form-item label="指定供应商：" v-if="basicinfoData.sharedSaleType == 2">
            <div>
              <span class="box-1-span" v-for="(item, index) in basicinfoData.businessname" :key="index">
                {{ item }}</span>
            </div>
          </el-form-item>
          <el-form-item label="销售区域" class="color666">
            <span>{{ basicinfoData.businessDistrictname || "无" }}
              
                <span
                  class="click-box"
                  v-if="basicinfoData.businessDistrictname"
                  @click="viewDq(basicinfoData)"
                >
                 点击查看具体区域
                </span>
              </span>
          </el-form-item>
        </el-form> -->
      </div>
    </div>
    <div>
      <el-dialog :title="dialogTitle" :visible.sync="isDIS" width="700px">
        <div class="district">
          <!-- <div v-for="item in disData" :key="item">
            <el-tag>{{ item }}</el-tag>
          </div> -->
          <div class="district-box">
            <div
              v-for="(item, index) in disData"
              :key="index"
              @click="province(index)"
              :class="provinceIndex == index ? 'pitch-on' : ''"
            >
              {{ item.name }}
            </div>
          </div>
          <div
            v-if="disData[provinceIndex] && disData[provinceIndex].children"
            class="district-box"
          >
            <div
              v-for="(item, index) in disData[provinceIndex].children"
              :key="index"
              @click="city(index)"
              :class="cityIndex == index ? 'pitch-on' : ''"
            >
              {{ item.name }}
            </div>
          </div>
          <div
            v-if="disData[provinceIndex] && disData[provinceIndex].children"
            class="district-box"
          >
            <div
              v-for="(item, index) in disData[provinceIndex].children[cityIndex]
                .children"
              :key="index"
            >
              {{ item.name }}
            </div>
          </div>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button type="primary" @click="isDIS = false">关闭</el-button>
        </span>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
const { mapState, mapActions } = createNamespacedHelpers("commonIndex"); //vuex公共库
const Audit = createNamespacedHelpers("Audit");
export default {
  props: {
    basicInfo: {
      type: Array,
      default: () => {
        return [];
      },
    },
    basicinfoData: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {
      isDIS: false,
      disData: [],
      dialogTitle: "",
      provinceIndex: 0,
      cityIndex: 0,
      // districtIndex: 0,
    };
  },
  methods: {
    ...Audit.mapActions(["getgoodsAuditdistrict"]),
    province(val) {
      this.cityIndex = 0;
      this.provinceIndex = val;
    },
    city(val) {
      this.cityIndex = val;
    },
    async viewDq(row) {
      let data = await this.getgoodsAuditdistrict({
        businessDistrictId: row.businessDistrictId,
      });
      if (data.code == 200) {
        this.disData = data.data;
        this.dialogTitle = "【" + row.businessDistrictname + "】的具体区域";
        this.isDIS = true;
      } else {
        this.$message({
          message: data.message,
          type: "warning",
        });
      }
    },
  },
};
</script>
<style lang='scss' scoped>
.el-descriptions-item__label:not(.is-bordered-label) {
  margin-right: 10px;
  display: flex;
  align-items: center;
}
.input-1 .el-input__inner {
  width: 40%;
  min-width: 300px;
}
</style>
<style lang="scss" scoped>
.message-box {
  padding: 10px;
  width: 100%;
  //   display: flex;
  //   flex-wrap: wrap;
  //   justify-content: space-between;
  .title {
    width: 100%;
    font-weight: bold;
    border-bottom: 1px dashed #ccc;
    padding-bottom: 15px;
    margin-bottom: 15px;
  }
  .title::before {
    width: 4px;
    height: 16px;
    background-color: #06b7ae;
    border-radius: 10px;
    display: inline-block;
    content: "";
    margin-right: 10px;
    margin-bottom: -2px;
  }
  .message-box-box {
    padding-top: 20px;
    padding-left: 20px;
    margin: 10px 0;
    // border: 1px solid #797979;
  }
  .box-1-span {
    margin-right: 10px;
  }
}
.color666 {
  font-size: 14px;
  color: #666;
  span {
    color: #333;
    margin-left: 5px;
  }
}
.district {
  display: flex;
  > div {
    flex: 1;
  }
}
.district-box {
  height: 300px;
  overflow-y: scroll;

  div {
    line-height: 40px;
    padding-left: 20px;
  }
  .pitch-on {
    color: #00bfbf;
  }
}
.message-box-box {
  display: flex;
  font-size: 14px;
  > div {
    flex: 1;
    display: flex;
    color: #666;
    > div {
      margin-left: 44px;
      color: #333;
    }
    .click-box {
      color: #00bfbf;
      cursor: pointer;
      display: inline-block;
      margin-left: 5px;
    }
  }
}
.el-dropdown-link {
    cursor: pointer;
    color: #00bfbf;
  }
</style>
