<template>
  <div class="MERCH goodshared">
    <div class="merch-card">
      <div class="message-box scorr-roll">
        <!-- 未通过显示框 -->
        <template v-if="AuditbusinessData.auditInfo">
          <div
            v-if="
              AuditbusinessData.auditInfo.status == 3 ||
              AuditbusinessData.auditInfo.status == 2
            "
          >
            <NotPassGood :UserQualification="AuditbusinessData.auditInfo" />
          </div>
        </template>
        <!-- 基本信息 -->
        <div>
          <!-- <MessageGood
            :basicInfo="goodsRedact.basicInfo"
            :basicinfoData="AuditbusinessData.basicInfo"
          /> -->
          <MerchantGood
            :businessInfo="goodsRedact.businessInfo"
            :businessInfoNew="AuditbusinessData.business"
          />
        </div>
        <!-- 商品共享信息 -->
        <div>
          <Shared :basicinfoData="AuditbusinessData.shareInfo" />
        </div>
        <div>
          <BasicMessageGood
            :basicInfo="goodsRedact.basicInfo"
            :picture="goodsMessage.picture"
            :manufacturerInfo="goodsMessage.manufacturerInfo"
            :label="goodsMessage.label"
            :basicinfoData="AuditbusinessData.basicInfo"
            :imgDate="shareMess.picture"
            :manuData="shareMess.manufacturerInfo"
            :labData="shareMess.label"
          />
        </div>
        <!-- 价格库存 -->
        <div>
          <PriceGood
            :priceAndInventory="goodsMessage.priceAndInventory"
            :priceData="shareMess.basic_info"
          />
        </div>
        <!-- 销售设置 -->
        <div>
          <SellSetGood
            :saleSetting="goodsMessage.saleSetting"
            :saleData="shareMess.basic_info"
          />
        </div>
        <!-- 产品资质 -->
        <div>
          <QualificationGood
            :qualify="goodsMessage.qualify"
            :qualifyData="shareMess.qualify"
          />
        </div>
        <!-- 平台分佣 -->
        <div>
          <el-dialog
            title="设置商品平台提成比例"
            :visible.sync="ispass"
            width="400px"
          >
            <div>
              <el-form label-width="80px">
                <el-form-item label="分佣比列">
                  <el-input
                    v-model="AuditMessage.shared_brokerage_ratio"
                    placeholder="请输入分佣比例"
                    type="number"
                    @change="changeratio(AuditMessage.shared_brokerage_ratio)"
                  >
                  </el-input>
                </el-form-item>
              </el-form>
              <span v-if="AuditbusinessData.shareInfo" class="lh24">
                不能超过供应商设置的分佣比例
                {{ AuditbusinessData.shareInfo.sharedBrokerageRatio }}
                %，平台提成比例设置后将只能调减不能调增，请妥善设置
              </span>
            </div>
            <span slot="footer" class="dialog-footer">
              <el-button @click="ispass = false">取 消</el-button>
              <el-button type="primary" @click="nextPass">确 定</el-button>
            </span>
          </el-dialog>
        </div>
        <!-- 拒绝 -->
        <!-- 弹框 -->
        <div>
          <el-dialog title="审核驳回提醒" :visible.sync="isRefuse" width="400px">
            <div class="explain">
              确定驳回当前【共享商品】审核？如果当前商品资质或信息不符合平台要求，请在驳回原因中注明
            </div>
            <div class="reason">
              <span>驳回原因：</span>

              <el-input
                type="textarea"
                :rows="2"
                maxlength="200"
                show-word-limit
                placeholder="请输入内容"
                v-model="AuditMessage.remark"
              >
              </el-input>
            </div>
            <span slot="footer" class="dialog-footer">
              <el-button @click="isRefuse = false">取 消</el-button>
              <el-button type="primary" @click="notarizeRefuse"
                >确 定</el-button
              >
            </span>
          </el-dialog>
        </div>
      </div>
      <template v-if="AuditbusinessData.auditInfo">
        <div class="btn-box" v-if="AuditbusinessData.auditInfo.status == 1">
          <div class="go-back" @click="toback">返回</div>
          <div class="refuse" @click="refuseMessage">审核驳回</div>
          <div class="pass" @click="passMessage">审核通过</div>
        </div>
        <div class="btn-box" v-else>
          <div class="go-back" @click="toback">返回</div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import SellSetGood from "@/components/ordinaryGoods/sellSet.vue"; //销售设置
import PriceGood from "@/components/ordinaryGoods/price.vue"; //价格/库存
import QualificationGood from "@/components/ordinaryGoods/qualification.vue"; //产品资质
import MerchantGood from "@/components/ordinaryGoods/merchant.vue"; //供应商
import BasicMessageGood from "@/components/ordinaryGoods/basicMessage.vue"; //基本信息
import MessageGood from "../../../components/ordinaryGoods/message.vue"; //基本信息
import NotPassGood from "./comp/notPass.vue"; //产品资质
import Shared from "./comp/shared.vue";
import { createNamespacedHelpers } from "vuex";
const { mapState, mapActions } = createNamespacedHelpers("Goods"); //vuex公共库
export default {
  data() {
    return {
      shareMess: {},
      activeName: 0,
      ispass: false,
      isRefuse: false,
      AuditMessage: {
        id: this.$route.query.id,
        remark: "",
        status: 2,
        shared_brokerage_ratio: 0,
      },
      goodsMessage: require("@/assets/json/cloudGood.json"),
      goodsRedact: require("@/assets/json/goodRedact.json"),
      
    };
  },
  components: {
    MessageGood, //基本信息
    NotPassGood, //未通过
    Shared, //共享信息
    MerchantGood,
    BasicMessageGood,
    SellSetGood, //销售设置
    PriceGood, //价格/库存
    QualificationGood, //产品资质
  },
  created() {
    if (this.$route.query.id) {
      this.getInfodetail();
      // this.getgoodsInfodetail({
      //   id: this.$route.query.platform_goods_info_id,
      // });
      this.postgoodsAuditbusinessDetail({
        id: this.$route.query.id,
      });
    }
  },
  computed: {
    ...mapState(["AuditbusinessData"]),
  },
  methods: {
    ...mapActions([
      "postgoodsAuditbusinessDetail",
      "postgoodsAuditeditMerchant",
      "getgoodsInfodetail",
    ]),
    // 获取审核商品信息
    async getInfodetail() {
      let res = await this.getgoodsInfodetail({
        id: this.$route.query.platform_goods_info_id,
      });
      let data = res.data;
      data.picture = [];
      data.commodity_images.forEach((item) => {
        let obj = {};
        obj.url = item;
        data.picture.push(obj);
      });
      data.manufacturerInfo = {
        manufacturer: data.basic_info.manufacturer,
        manufacturerReferred: data.basic_info.short_name,
        productionAddress: data.basic_info.production_address,
        listingLicenseHolder: data.basic_info.marketing_authorization_holder,
      };
      data.label = {
        overTheCounter: data.basic_info.over_the_counter,
        isCollect: data.basic_info.isCollect,
      };
      // data.priceAndInventory={
      //   packages:data.basic_info.packages,
      //   piece:data.basic_info.piece,
      //   expirationDate:data.basic_info.expirationDate,
      //   productionDate:data.basic_info.productionDate,
      //   marketPrice:data.basic_info.marketPrice,
      //   settlementPrice:data.basic_info.settlementPrice,
      //   sellingPrice:data.basic_info.sellingPrice,
      //   quantity:data.basic_info.quantity,
      // }
      // data.saleSetting=data.basic_info
      data.qualify = [];
      data.product_qualification.forEach((item) => {
        let obj = {};
        obj.qualifyName = item.name;
        obj.qualifyUrl = item.images[0];
        data.qualify.push(obj);
      });
      this.shareMess = data;
    },
    // 打开拒绝弹框
    refuseMessage() {
      this.AuditMessage = {
        id: this.$route.query.id,
        remark: "",
        status: 3,
      };
      this.isRefuse = true;
    },
    // 打开通过弹框
    passMessage() {
      this.AuditMessage = {
        id: this.$route.query.id,
        status: 2,
        shared_brokerage_ratio: 0,
      };
      this.ispass = true;
    },
    // 超出判断
    changeratio(val) {
      // if (val < this.AuditbusinessData.shareinfo.sharedbrokerageratio) {
      //   this.AuditMessage.shared_brokerage_ratio = val;
      // } else {
      //   this.AuditMessage.shared_brokerage_ratio =
      //     this.AuditbusinessData.shareinfo.sharedbrokerageratio - 1;
      //   this.$message({
      //     message: "必须小于供应商设置比例",
      //     type: "warning",
      //   });
      // }
    },
    // 确认拒绝
    async notarizeRefuse() {
      if(!this.AuditMessage.remark||this.AuditMessage==''){
        this.$message({
          message: '您还没有填写驳回原因，请填写后重试。',
          type: "warning",
        });
        return 
      }

      let data = await this.postgoodsAuditeditMerchant(this.AuditMessage);
      if (data.code == 200) {
        this.$router.push({ path: "/goodsCheck" });
        this.$message({
          message: data.message,
          type: "success",
        });
      } else {
        this.$message({
          message: data.message,
          type: "warning",
        });
      }
    },
    // 确认
    async nextPass() {
      if (
        Number(this.AuditMessage.shared_brokerage_ratio) >=
        Number(this.AuditbusinessData.shareInfo.sharedBrokerageRatio)
      ) {
        this.$message({
          message: "必须小于供应商设置比例",
          type: "warning",
        });
        return;
      }
      this.AuditMessage.goods_info_id =
        this.$route.query.platform_goods_info_id;
      let data = await this.postgoodsAuditeditMerchant(this.AuditMessage);
      if (data.code == 200) {
        this.$router.push({ path: "/goodsCheck" });
        this.$message({
          message: data.message,
          type: "success",
        });
      } else {
        this.$message({
          message: data.message,
          type: "warning",
        });
      }
    },
    // 返回
    toback() {
      this.$router.push({ path: "/goodsCheck" });
    },
  },
};
</script>
<style lang="scss" scoped>
.dio-input {
  .el-input {
    width: 300px !important;
  }
  .el-upload--picture-card {
    width: 100px;
    height: 100px;
    line-height: 100px;
  }
}
.el-tabs__item.is-active {
  color: #06b7ae;
}
.el-tabs__item:hover {
  color: #06b7ae;
}
.el-tabs__active-bar {
  background-color: #06b7ae;
}
.el-input-group__prepend {
  padding: 0px 0px 0px 5px !important;
}
.el-input__inner {
  border-radius: 4px !important;
}
.el-range-separator {
  width: 20% !important;
}
</style>
<style lang="scss" scoped>
.userBtn {
  display: flex;
  justify-content: center;
  div {
    margin-left: 5px;
    color: #1abca9;
    cursor: pointer;
  }
}
.MERCH {
  width: 100%;
  height: 100%;
  .merch-card {
    width: 100%;
    height: 100%;
    padding: 20px;
    padding-top: 10px;
    border-radius: 4px;
    box-shadow: 0 2px 12px 0 rgb(0 0 0 / 10%);
    background: #ffffff;
    .merch-tag {
      width: 100%;
      height: 40px;
    }
    .message-box {
      width: 100%;
      max-height: 75vh;
      padding-top: 10px;
    }
  }
  .btn-box {
    margin-top: 1%;
    width: 100%;
    height: 32px;
    display: flex;
    align-content: center;
    justify-content: center;
    div {
      font-size: 14px;
      text-align: center;
      border-radius: 4px;
      font-weight: normal;
      line-height: 32px;
      cursor: pointer;
    }
    .go-back {
      width: 88px;
      color: rgba($color: #1c1f23, $alpha: 0.6);
      border: 1px solid #d4d7d9;
    }
    .refuse {
      width: 116px;
      background: #f6b142;
      color: #fff;
      margin: 0 20px;
    }
    .pass {
      width: 116px;
      background: #00bfbf;
      color: #ffffff;
    }
  }
}
.explain{
  line-height: 24px;
}
.reason {
  display: flex;
  line-height: 24px;
  margin-top: 10px;
  span {
    width: 100px ;
  }
}
::v-deep .el-dialog__body{
  padding: 20px;
  padding-top: 0;
}
::v-deep .el-textarea__inner{
  // width: 300px !important
}
.lh24{
  line-height: 24px;
}
</style>
